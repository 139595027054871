import { Invoice } from "@/types/invoice";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ErrorSummary } from "@/types/errors";
import { InvoiceDetails } from "@/types/invoiceDetails";

type InvoiceDetailsState = {
  invoice: InvoiceDetails | undefined;
  errors: ErrorSummary[];
};

const initialState: InvoiceDetailsState = {
  invoice: undefined,
  errors: [],
};

type SetInvoiceDetailsPayload = {
  invoice: InvoiceDetails | undefined;
  errorSummaries: ErrorSummary[];
};

export const invoiceDetailsSlice = createSlice({
  name: "invoiceDetails",
  initialState,
  reducers: {
    setInvoiceDetails: (
      state,
      action: PayloadAction<SetInvoiceDetailsPayload>
    ) => {
      state.invoice = action.payload.invoice;
      state.errors = action.payload.errorSummaries;
    },
  },
});

export const { setInvoiceDetails } = invoiceDetailsSlice.actions;

export const selectInvoice = (state: RootState) => state.invoiceDetails.invoice;
export const selectInvoiceErrorSummaries = (state: RootState) =>
  state.invoiceDetails.errors;

export default invoiceDetailsSlice.reducer;
