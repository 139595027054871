import {
  ActiveFilter,
  CriterionNumberFilterValue,
  Filter,
  FilterLinker,
  SelectFilterOption,
  SerializedDateRange,
} from "@/types/filter";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type creditFiltersState = {
  filters: Record<string, Filter> | undefined;
  activeFilters: ActiveFilter[];
  filterLinkers: FilterLinker[];
  dateRange: SerializedDateRange | undefined;
  allDatesRange: SerializedDateRange | undefined;
  searchString: string;
};

const initialState: creditFiltersState = {
  filters: {},
  activeFilters: [],
  filterLinkers: [],
  dateRange: undefined,
  allDatesRange: undefined,
  searchString: "",
};

type SetCreditFiltersPayload = {
  filters: Record<string, Filter>;
};

type SetDateRangePayload = {
  dateRange: SerializedDateRange | undefined;
};

type SetSingleSelectActiveFilterValuePayload = {
  index: number;
  item: SelectFilterOption;
};

type UpdateCriterionNumberFilterPayload = {
  index: number;
  value: CriterionNumberFilterValue;
};

type UpdateFilterLinkerPayload = {
  index: number;
  value: FilterLinker;
};

export const creditFiltersSlice = createSlice({
  name: "creditFilters",
  initialState,
  reducers: {
    setCreditFilters: (
      state,
      action: PayloadAction<SetCreditFiltersPayload>,
    ) => {
      state.filters = action.payload.filters;
    },
    setCreditDateRange: (state, action: PayloadAction<SetDateRangePayload>) => {
      state.dateRange = action.payload.dateRange;
    },
    setCreditAllDatesRange: (state, action: PayloadAction<SetDateRangePayload>) => {
      state.allDatesRange = action.payload.dateRange;
    },
    setCreditSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    appendCreditFilter: (state, action: PayloadAction<ActiveFilter>) => {
      if (state.activeFilters.length !== 0) {
        state.filterLinkers.push(FilterLinker.OR);
      }

      state.activeFilters.push(action.payload);
    },
    setCreditSingleSelectActiveFilterValue: (
      state,
      action: PayloadAction<SetSingleSelectActiveFilterValuePayload>,
    ) => {
      state.activeFilters[action.payload.index].filter = action.payload.item;
    },
    updateCreditCriterionNumberFilter: (
      state,
      action: PayloadAction<UpdateCriterionNumberFilterPayload>,
    ) => {
      const currentFilterValue = state.activeFilters[action.payload.index];
      if (currentFilterValue) {
        if (!currentFilterValue.filter) {
          currentFilterValue.filter = action.payload.value;
          return;
        }
        if ("criteria" in action.payload.value) {
          (currentFilterValue.filter as CriterionNumberFilterValue).criteria =
            action.payload.value.criteria;
        }
        if ("value" in action.payload.value) {
          (currentFilterValue.filter as CriterionNumberFilterValue).value =
            action.payload.value.value;
        }
      }
    },
    updateCreditFilterLinkerWithIndex: (
      state,
      action: PayloadAction<UpdateFilterLinkerPayload>,
    ) => {
      state.filterLinkers[action.payload.index] = action.payload.value;
    },
    removeCreditActiveFilterWithIndex: (
      state,
      action: PayloadAction<number>,
    ) => {
      // Remove linker
      const filterIndex = action.payload;
      if (filterIndex === state.activeFilters.length - 1) {
        state.filterLinkers.pop();
      } else if (filterIndex >= 0) {
        state.filterLinkers.splice(filterIndex, 1);
      }

      state.activeFilters.splice(filterIndex, 1);
    },
    clearAllCreditActiveFilters: (state) => {
      state.activeFilters = [];
      state.filterLinkers = [];
    },
  },
});

export const {
  setCreditFilters,
  setCreditDateRange,
  setCreditAllDatesRange,
  setCreditSearchString,
  setCreditSingleSelectActiveFilterValue,
  appendCreditFilter,
  updateCreditCriterionNumberFilter,
  updateCreditFilterLinkerWithIndex,
  removeCreditActiveFilterWithIndex,
  clearAllCreditActiveFilters,
} = creditFiltersSlice.actions;

export const selectCreditFilters = (state: RootState) =>
  state.creditFilters.filters;
export const selectActiveCreditFilters = (state: RootState) =>
  state.creditFilters.activeFilters;
export const selectCreditDateRange = (state: RootState) =>
  state.creditFilters.dateRange;
export const selectCreditAllDatesRange = (state: RootState) =>
  state.creditFilters.allDatesRange;
export const selectCreditSearchString = (state: RootState) =>
  state.creditFilters.searchString;
export const selectCreditFilterLinkers = (state: RootState) =>
  state.creditFilters.filterLinkers;

export default creditFiltersSlice.reducer;
