export enum CriterionNumberOptionKey {
  GT = "gt",
  LT = "lt",
  EQ = "eq",
  GE = "ge",
  LE = "le",
  NE = "ne",
}
export type CriterionNumberFilterOperator = {
  key: CriterionNumberOptionKey;
  desc: string;
};

export type CriterionNumberFilterValue = {
  criteria?: CriterionNumberFilterOperator;
  value?: string;
};

export type SelectFilterOption = {
  key: string;
  value: string;
};

export type CriterionNumberFilterFormat = "currency" | "number";

export type CriterionNumberFilter = {
  type: FilterType.CriterionNumber;
  options: {
    label: string;
    format: CriterionNumberFilterFormat;
    selectPlaceholder: string;
    inputPlaceholder: string;
    criterions: CriterionNumberFilterOperator[];
  };
};

export type CommonSelectFilterOptions = {
  options: {
    label: string;
    values: SelectFilterOption[];
  };
  search?: boolean;
};

export type SingleSelectFilter = {
  type: FilterType.SingleSelect;
} & CommonSelectFilterOptions;

export type MultiSelectFilter = {
  type: FilterType.MultiSelect;
} & CommonSelectFilterOptions;

export type Filter =
  | CriterionNumberFilter
  | SingleSelectFilter
  | MultiSelectFilter;

export type ActiveFilter = {
  filterKey: string;
  filter: SelectFilterOption | CriterionNumberFilterValue | undefined;
};

export enum FilterType {
  SingleSelect = "single_select",
  MultiSelect = "multi_select",
  CriterionNumber = "criterion_number",
  DateRange = "date_range",
}

export const InvoiceFilterKeyToDataFieldMap = {
  LOGISTICS_PROVIDER_NAME: "logistics_provider",
  WAREHOUSE: "warehouse",
  STATUS: "status",
  UNFAVORABLE_COUNT: "unfavorable_errors_count",
  FAVORABLE_COUNT: "favorable_errors_count",
  UNFAVORABLE_DOLLARS: "unfavorable_errors_dollars",
  UNFAVORABLE_REJECTED_DOLLARS: "unfavorable_errors_rejected_dollars",
  FAVORABLE_DOLLARS: "favorable_errors_dollars",
  INVOICE_TYPE: "services",
  LOGISTICS_CHARGES: "logistics_charges",
};

export const OrderFilterKeyToDataFieldMap = {
  ERROR_TYPE: "error_name",
  STATUS: "status",
  SERVICE_CHARGES: "expected_rate",
  LOGISTICS_CHARGES: "invoiced_rate",
  SERVICE: "services",
};

export const CreditFilterKeyToDataFieldMap = {
  STATUS: "status",
  SUBMIT_DATE: "submit_date",
  LOGISTICS_PROVIDER: "logistics_provider",
  REQUESTED_CREDIT: "errors_dollars",
  RECEIVED_CREDIT: "received_credit",
};

export type SerializedDateRange = {
  from: number | undefined;
  to?: number | undefined;
};

export enum FilterLinker {
  AND = "and",
  OR = "or",
}
