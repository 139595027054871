import { OrderDetails } from "@/types/orderDetails";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SalesOrderStatus } from "@api/core/logistics/sales-orders/sales-orders.schema";

type SetAllOrderDetailsPayload = {
  allOrderDetails: OrderDetails[];
};

type SetOrderDetailsPayload = {
  orderDetails: OrderDetails | undefined;
};

type SetOrderStatusStatePayload = {
  status: SalesOrderStatus;
};

export type OrderDetailsState = {
  allOrderDetails: OrderDetails[];
  orderDetails: OrderDetails | undefined;
};

const initialState: OrderDetailsState = {
  allOrderDetails: [],
  orderDetails: undefined,
};

const orderDetailsSlice = createSlice({
  name: "orderDetails",
  initialState,
  reducers: {
    setOrderDetails: (state, action: PayloadAction<SetOrderDetailsPayload>) => {
      state.orderDetails = action.payload.orderDetails;
    },
    setAllOrderDetails: (
      state,
      action: PayloadAction<SetAllOrderDetailsPayload>
    ) => {
      state.allOrderDetails = action.payload.allOrderDetails;
    },
    updateOrderDetailsAnomalyState: (
      state,
      action: PayloadAction<SetOrderStatusStatePayload>
    ) => {
      if (state.orderDetails)
        state.orderDetails.anomaly.status = action.payload.status;
    },
    updateOrderDetailStatus: (
      state,
      action: PayloadAction<SetOrderStatusStatePayload>
    ) => {
      if (state.orderDetails) state.orderDetails.status = action.payload.status;
    },
  },
});

export const {
  setOrderDetails,
  setAllOrderDetails,
  updateOrderDetailsAnomalyState,
  updateOrderDetailStatus,
} = orderDetailsSlice.actions;

export const selectAllOrderDetails = (state: RootState) =>
  state.orderDetails.allOrderDetails;
export const selectOrderDetails = (state: RootState) =>
  state.orderDetails.orderDetails;

export default orderDetailsSlice.reducer;
