export type Credit = {
  id: string;
  invoice_number: string;
  requested_credit: number;
  received_credit: number;
  submit_date: string;
  status: string;
  credit_request_number: string;
  logistics_provider_name: string;
  errors_dollars: number;
};

export enum CreditStatus {
  DENIED = "DENIED",
  SENT = "SENT",
  RECEIVED = "RECEIVED",
  PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
  CANCELLED = "CANCELLED",
}

export type CreditStatusHighlightCountTotals = {
  requested: number;
  received: number;
  unreceived: number;
};

export type CreditStatusChartCountTotals = {
  received: number;
  partially_received: number;
  denied: number;
  cancelled: number;
  sent: number;
};

export enum CreditTrackerTableHeader {
  CREDIT_REQUEST = "Credit Request",
  INVOICE = "Invoice",
  STATUS = "Status",
  SUBMIT_DATE = "Submit Date",
  LOGISTICS_PROVIDER = "3PL",
  REQUESTED_CREDIT = "Requested Credit",
  RECEIVED_CREDIT = "Received Credit",
  ACTION = "Action",
}

export type CreditSortCriteria = {
  key: CreditTrackerTableHeader;
  order: "ascending" | "descending";
};
