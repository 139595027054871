"use client";

import { MOCK_DATA_ENABLED } from "@/config";
import { useEffect, useState } from "react";

export function MockProvider({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [mockingEnabled, setMockingEnabled] = useState(false);

  useEffect(() => {
    async function enableApiMocking() {
      if (typeof window !== "undefined" && MOCK_DATA_ENABLED) {
        const { worker } = await import("../services/mocks/browser");
        await worker.start();
      }

      setMockingEnabled(true);
    }

    enableApiMocking();
  }, []);

  if (!mockingEnabled) {
    return null;
  }

  return <>{children}</>;
}
