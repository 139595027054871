export type Order = {
  order_number: string;
  status: string;
  error_code?: string[];
  error_name: string[];
  fulfill_date?: string;
  invoice_name?: string;
  expected_rate?: number;
  invoiced_rate?: number;
  operator?: string;
  unfavorable_dollars: number;
  unfavorable_count: number;
  favorable_dollars: number;
  favorable_count: number;
  display_error_dollars: number | null;
  services: string[];
};

export enum OrderTableHeader {
  ORDER = "Order",
  STATUS = "Order Status",
  ERRORS = "Errors ($)",
  SERVICE = "Service",
  ERROR_TYPE = "Error Type",
  ADDITIONAL_ERRORS = "Additional Errors",
}

export type OrderSortCriteria = {
  key: OrderTableHeader;
  order: "ascending" | "descending";
};

export type OrderTotalProps = {
  data: Order[];
  total: {
    expected_rate: number;
    invoiced_rate: number;
    operator: string;
    order_count: number;
    charges: number;
    error_count: number;
    error_dollars: number;
  };
  filtering_params: {
    FULFILL_DATE: null;
    STATUS: null;
    ERROR_CODE: null;
    LOGISTICS_CHARGES: null;
    SERVICE_CHARGES: null;
    UNFAVORABLE_COUNT: null;
  };
};
