import {
  ActiveFilter,
  CriterionNumberFilterValue,
  Filter,
  FilterLinker,
  SelectFilterOption,
} from "@/types/filter";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type SetOrderFiltersPayload = {
  filters: Record<string, Filter>;
};

type SetSingleSelectActiveFilterValuePayload = {
  index: number;
  item: SelectFilterOption;
};

type UpdateCriterionNumberFilterPayload = {
  index: number;
  value: CriterionNumberFilterValue;
};

type UpdateFilterLinkerPayload = {
  index: number;
  value: FilterLinker;
};

type OrderFiltersState = {
  filters: Record<string, Filter> | undefined;
  activeFilters: ActiveFilter[];
  filterLinkers: FilterLinker[];
  searchString: string;
  currentQuery: string[][];
};

const initialState: OrderFiltersState = {
  filters: undefined,
  activeFilters: [],
  filterLinkers: [],
  searchString: "",
  currentQuery: [],
};

export const orderFiltersSlice = createSlice({
  name: "orderFilters",
  initialState,
  reducers: {
    setOrderFilters: (state, action: PayloadAction<SetOrderFiltersPayload>) => {
      state.filters = action.payload.filters;
    },
    setOrderSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    appendOrderFilter: (state, action: PayloadAction<ActiveFilter>) => {
      state.activeFilters.push(action.payload);

      if (state.activeFilters.length !== 0) {
        state.filterLinkers.push(FilterLinker.OR);
      }
    },
    appendOrderFilterWithLinker: (
      state,
      action: PayloadAction<{
        filter: ActiveFilter;
        linker?: FilterLinker;
      }>
    ) => {
      if (state.activeFilters.length !== 0) {
        state.filterLinkers.push(action.payload.linker || FilterLinker.AND);
      }
      state.activeFilters.push(action.payload.filter);
    },
    setOrderFiltersAndLinkers: (
      state,
      action: PayloadAction<{
        filters: ActiveFilter[];
        linkers: FilterLinker[];
      }>
    ) => {
      state.activeFilters = action.payload.filters;
      state.filterLinkers = action.payload.linkers;
    },
    setOrderSingleSelectActiveFilterValue: (
      state,
      action: PayloadAction<SetSingleSelectActiveFilterValuePayload>
    ) => {
      state.activeFilters[action.payload.index].filter = action.payload.item;
    },
    updateOrderCriterionNumberFilter: (
      state,
      action: PayloadAction<UpdateCriterionNumberFilterPayload>
    ) => {
      const currentFilterValue = state.activeFilters[action.payload.index];
      if (currentFilterValue) {
        if (!currentFilterValue.filter) {
          currentFilterValue.filter = action.payload.value;
          return;
        }
        if ("criteria" in action.payload.value) {
          (currentFilterValue.filter as CriterionNumberFilterValue).criteria =
            action.payload.value.criteria;
        }
        if ("value" in action.payload.value) {
          (currentFilterValue.filter as CriterionNumberFilterValue).value =
            action.payload.value.value;
        }
      }
    },
    updateOrderFilterLinkerWithIndex: (
      state,
      action: PayloadAction<UpdateFilterLinkerPayload>
    ) => {
      state.filterLinkers[action.payload.index] = action.payload.value;
    },
    removeOrderActiveFilterWithIndex: (
      state,
      action: PayloadAction<number>
    ) => {
      // Remove linker
      const filterIndex = action.payload;
      if (filterIndex === state.activeFilters.length - 1) {
        state.filterLinkers.pop();
      } else if (filterIndex >= 0) {
        state.filterLinkers.splice(filterIndex, 1);
      }

      state.activeFilters.splice(filterIndex, 1);
    },
    clearAllOrderActiveFilters: (state) => {
      state.activeFilters = [];
      state.filterLinkers = [];
    },
    setCurrentOrderQuery: (state, action: PayloadAction<string[][]>) => {
      state.currentQuery = action.payload;
    },
  },
});

export const {
  setOrderFilters,
  setOrderSearchString,
  appendOrderFilter,
  updateOrderCriterionNumberFilter,
  setOrderSingleSelectActiveFilterValue,
  updateOrderFilterLinkerWithIndex,
  removeOrderActiveFilterWithIndex,
  clearAllOrderActiveFilters,
  appendOrderFilterWithLinker,
  setOrderFiltersAndLinkers,
  setCurrentOrderQuery,
} = orderFiltersSlice.actions;

export const selectOrderFilters = (state: RootState) =>
  state.orderFilters.filters;
export const selectActiveOrderFilters = (state: RootState) =>
  state.orderFilters.activeFilters;
export const selectOrderSearchString = (state: RootState) =>
  state.orderFilters.searchString;
export const selectOrderFilterLinkers = (state: RootState) =>
  state.orderFilters.filterLinkers;
export const selectCurrentOrderQuery = (state: RootState) =>
  state.orderFilters.currentQuery;

export default orderFiltersSlice.reducer;
