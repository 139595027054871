import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { Invoice, InvoiceHightlightTotals } from "@/types/invoice";

type SetAllInvoicesPayload = {
  invoices: Invoice[];
};

type SetInvoicesHightlightTotalPayload = {
  invoiceHighlightTotals: InvoiceHightlightTotals;
};

type InvoicesState = {
  allInvoices: Invoice[];
  currentPage: number;
  itemsPerPage: number | undefined;
  totalItemsCount: number;
  invoiceHightlightTotals: InvoiceHightlightTotals;
};

const initialState: InvoicesState = {
  allInvoices: [],
  currentPage: 0,
  itemsPerPage: undefined,
  totalItemsCount: 0,
  invoiceHightlightTotals: {
    savingsDollars: 0,
    savingsCount: 0,
    requestedDollars: 0,
    requestedCount: 0,
    recoverableDollars: 0,
    totalCount: 0,
    totalCountOutstanding: 0,
  },
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setAllInvoices: (state, action: PayloadAction<SetAllInvoicesPayload>) => {
      state.allInvoices = action.payload.invoices;
    },
    setInvoiceCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setInvoiceItemsPerPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
    },
    setInvoiceTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload;
    },
    setInvoiceHighlightTotals: (
      state,
      action: PayloadAction<SetInvoicesHightlightTotalPayload>
    ) => {
      state.invoiceHightlightTotals = action.payload.invoiceHighlightTotals;
    },
  },
});

export const {
  setAllInvoices,
  setInvoiceCurrentPage,
  setInvoiceItemsPerPage,
  setInvoiceTotalItemsCount,
  setInvoiceHighlightTotals,
} = invoicesSlice.actions;

export const selectAllInvoices = (state: RootState) =>
  state.invoices.allInvoices;
export const selectInvoiceCurrentPage = (state: RootState) =>
  state.invoices.currentPage;
export const selectInvoiceItemsPerPage = (state: RootState) =>
  state.invoices.itemsPerPage;
export const selectInvoiceTotalItemsCount = (state: RootState) =>
  state.invoices.totalItemsCount;
export const selectInvoiceHightlightTotals = (state: RootState) =>
  state.invoices.invoiceHightlightTotals;

export default invoicesSlice.reducer;
