import { configureStore } from "@reduxjs/toolkit";
import invoicesReducer from "./invoices/invoicesSlice";
import invoiceFiltersReducer from "./invoices/invoiceFiltersSlice";
import invoiceSortReducer from "./invoices/invoiceSortSlice";
import invoiceDetailsReducer from "./invoices/invoiceDetailsSlice";
import orderFiltersReducer from "./orders/orderFiltersSlice";
import ordersReducer from "./orders/ordersSlice";
import orderSortReducer from "./orders/orderSortSlice";
import servicesDetailsReducer from "./services/servicesDetailsSlice";
import serviceSortReducer from "./services/serviceSortSlice";
import orderDetailsReducer from "./orders/orderDetailsSlice";
import requestCreditsReducer from "./invoices/requestCreditsSlice";
import creditsReducer from "./credits/creditsSlice";
import creditSortReducer from "./credits/creditSortSlice";
import creditPaginationReducer from "./credits/creditPaginationSlice";
import creditFiltersReducer from "./credits/creditFiltersSlice";
import authReducer from "./auth/authSlice";
import invoiceErrorSummaryReducer from "./invoices/invoiceErrorSummarySlice";
import errorsReducer from "./errors/errorsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    invoices: invoicesReducer,
    invoiceFilters: invoiceFiltersReducer,
    invoiceSort: invoiceSortReducer,
    invoiceDetails: invoiceDetailsReducer,
    orderFilters: orderFiltersReducer,
    orders: ordersReducer,
    orderSort: orderSortReducer,
    servicesDetails: servicesDetailsReducer,
    serviceSort: serviceSortReducer,
    orderDetails: orderDetailsReducer,
    requestCredits: requestCreditsReducer,
    credits: creditsReducer,
    creditSort: creditSortReducer,
    creditPagination: creditPaginationReducer,
    creditFilters: creditFiltersReducer,
    invoiceErrorSummarySlice: invoiceErrorSummaryReducer,
    errors: errorsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
