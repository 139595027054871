export enum ServiceAuditDepth {
  FULL_DEPTH = "Full Depth",
  SUMMARY_ONLY = "Summary Only",
}

export enum ServiceStatus {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  UNREVIEWED = "UNREVIEWED",
  NO_REVIEW_REQUIRED = "NO_REVIEW_REQUIRED",
}

export type ServiceDetail = {
  status: ServiceStatus | null;
  type: string;
  audit_depth: ServiceAuditDepth;
  service_name: string;
  unfavorable_errors_dollars: number;
  unfavorable_errors_count: number;
  favorable_errors_count: number;
  favorable_errors_dollars: number;
  logistics_charges: number;
};
export type ServiceDetails = {
  total: {
    unfavorable_errors_dollars: number;
    unfavorable_errors_count: number;
    favorable_errors_count: number;
    favorable_errors_dollars: number;
    logistics_charges: number;
  };
  data: ServiceDetail[];
};

export enum ServiceDetailsKey {
  PICK_AND_PACK = "pnp_services",
  SUMMARY = "summary_services",
  FREIGHT = "freight_services",
}

export enum PickAndPackTableHeader {
  INVOICE_LINES = "Invoice Lines",
  AUDIT_DEPTH = "Audit Depth",
  CHARGES = "Charges",
  ERRORS = "Errors",
}

export enum FreightTableHeader {
  CARRIER = "Carrier",
  SERVICE_LEVEL = "Service Level",
  BASE_FREIGHT = "Base Freight",
  ACCESSORIAL = "Accessorial",
  TOTAL = "Total",
  NUM_OF_ORDERS = "# of Orders",
  ERRORS = "Errors",
}

export enum ServiceSummaryTableHeader {
  SERVICES = "Services",
  AUDIT_DEPTH = "Audit Depth",
  CHARGES = "Charges",
  ERRORS = "Errors",
}

export type ServiceSortCriteria = {
  key: PickAndPackTableHeader | FreightTableHeader | ServiceSummaryTableHeader;
  order: "ascending" | "descending";
};

export enum ServicesTableServiceType {
  FREIGHT = "Outbound Freight",
  PICK_AND_PACK = "Pick and pack"
}