import { OrderSortCriteria, OrderTableHeader } from "@/types/order";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type SetOrderSortCriteriaPayload = {
  sortCriteria: OrderSortCriteria;
};

type OrderSortState = {
  sortCriteria: OrderSortCriteria;
};

const initialState: OrderSortState = {
  sortCriteria: {
    key: OrderTableHeader.STATUS,
    order: "ascending",
  },
};

export const OrderSortSlice = createSlice({
  name: "orderSort",
  initialState,
  reducers: {
    setOrderSortCriteria: (
      state,
      action: PayloadAction<SetOrderSortCriteriaPayload>
    ) => {
      state.sortCriteria = action.payload.sortCriteria;
    },
  },
});

export const { setOrderSortCriteria } = OrderSortSlice.actions;

export const selectOrderSortCriteria = (state: RootState) =>
  state.orderSort.sortCriteria;

export default OrderSortSlice.reducer;
