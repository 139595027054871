import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import {
  ActiveFilter,
  CriterionNumberFilterValue,
  Filter,
  FilterLinker,
  SelectFilterOption,
  SerializedDateRange,
} from "@/types/filter";

type SetInvoiceFiltersPayload = {
  filters: Record<string, Filter>;
};

type SetDateRangePayload = {
  dateRange: SerializedDateRange | undefined;
};

type SetSingleSelectActiveFilterValuePayload = {
  index: number;
  item: SelectFilterOption;
};

type UpdateCriterionNumberFilterPayload = {
  index: number;
  value: CriterionNumberFilterValue;
};

type UpdateFilterLinkerPayload = {
  index: number;
  value: FilterLinker;
};

type InvoiceFiltersState = {
  filters: Record<string, Filter> | undefined;
  activeFilters: ActiveFilter[];
  filterLinkers: FilterLinker[];
  dateRange: SerializedDateRange | undefined;
  allDatesRange: SerializedDateRange | undefined;
  searchString: string;
  activeActionTab: number;
};

const initialState: InvoiceFiltersState = {
  filters: undefined,
  activeFilters: [],
  filterLinkers: [],
  dateRange: undefined,
  allDatesRange: undefined,
  searchString: "",
  activeActionTab: 0,
};

export const invoiceFiltersSlice = createSlice({
  name: "invoiceFilters",
  initialState,
  reducers: {
    setInvoiceFilters: (
      state,
      action: PayloadAction<SetInvoiceFiltersPayload>
    ) => {
      state.filters = action.payload.filters;
    },
    setInvoiceDateRange: (
      state,
      action: PayloadAction<SetDateRangePayload>
    ) => {
      state.dateRange = action.payload.dateRange;
    },
    setInvoiceAllDatesRange: (
      state,
      action: PayloadAction<SetDateRangePayload>
    ) => {
      state.allDatesRange = action.payload.dateRange;
    },
    setInvoiceSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    appendInvoiceActiveFilter: (state, action: PayloadAction<ActiveFilter>) => {
      if (state.activeFilters.length !== 0) {
        state.filterLinkers.push(FilterLinker.OR);
      }
      state.activeFilters.push(action.payload);
    },
    setInvoiceSingleSelectActiveFilterValue: (
      state,
      action: PayloadAction<SetSingleSelectActiveFilterValuePayload>
    ) => {
      state.activeFilters[action.payload.index].filter = action.payload.item;
    },
    updateInvoiceCriterionNumberFilter: (
      state,
      action: PayloadAction<UpdateCriterionNumberFilterPayload>
    ) => {
      const currentFilterValue = state.activeFilters[action.payload.index];
      if (currentFilterValue) {
        if (!currentFilterValue.filter) {
          currentFilterValue.filter = action.payload.value;
          return;
        }
        if ("criteria" in action.payload.value) {
          (currentFilterValue.filter as CriterionNumberFilterValue).criteria =
            action.payload.value.criteria;
        }
        if ("value" in action.payload.value) {
          (currentFilterValue.filter as CriterionNumberFilterValue).value =
            action.payload.value.value;
        }
      }
    },
    updateInvoiceFilterLinkerWithIndex: (
      state,
      action: PayloadAction<UpdateFilterLinkerPayload>
    ) => {
      state.filterLinkers[action.payload.index] = action.payload.value;
    },
    removeInvoiceActiveFilterWithIndex: (
      state,
      action: PayloadAction<number>
    ) => {
      // Remove linker
      const filterIndex = action.payload;
      if (filterIndex === state.activeFilters.length - 1) {
        state.filterLinkers.pop();
      } else if (filterIndex >= 0) {
        state.filterLinkers.splice(filterIndex, 1);
      }

      state.activeFilters.splice(filterIndex, 1);
    },
    clearAllInvoiceActiveFilters: (state) => {
      state.activeFilters = [];
      state.filterLinkers = [];
    },
    setActiveActionTab: (state, action: PayloadAction<number>) => {
      state.activeActionTab = action.payload;
    },
  },
});

export const {
  setInvoiceFilters,
  setInvoiceDateRange,
  setInvoiceAllDatesRange,
  setInvoiceSearchString,
  setInvoiceSingleSelectActiveFilterValue,
  removeInvoiceActiveFilterWithIndex,
  updateInvoiceCriterionNumberFilter,
  clearAllInvoiceActiveFilters,
  appendInvoiceActiveFilter,
  updateInvoiceFilterLinkerWithIndex,
  setActiveActionTab,
} = invoiceFiltersSlice.actions;

export const selectActiveInvoiceFilters = (state: RootState) =>
  state.invoiceFilters.activeFilters;
export const selectInvoiceFilters = (state: RootState) =>
  state.invoiceFilters.filters;
export const selectInvoiceDateRange = (state: RootState) =>
  state.invoiceFilters.dateRange;
export const selectInvoiceAllDatesRange = (state: RootState) =>
  state.invoiceFilters.allDatesRange;
export const selectInvoiceSearchString = (state: RootState) =>
  state.invoiceFilters.searchString;
export const selectInvoiceFilterLinkers = (state: RootState) =>
  state.invoiceFilters.filterLinkers;
export const selectInvoiceActiveActionTab = (state: RootState) =>
  state.invoiceFilters.activeActionTab;

export default invoiceFiltersSlice.reducer;
