import {
  FreightTableHeader,
  PickAndPackTableHeader,
  ServiceDetailsKey,
  ServiceSortCriteria,
  ServiceSummaryTableHeader,
} from "@/types/service";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type ServiceSortState = {
  sortCriteria: Partial<Record<ServiceDetailsKey, ServiceSortCriteria>>;
};

const initialState: ServiceSortState = {
  sortCriteria: {
    [ServiceDetailsKey.PICK_AND_PACK]: {
      key: PickAndPackTableHeader.INVOICE_LINES,
      order: "ascending",
    },
    [ServiceDetailsKey.SUMMARY]: {
      key: ServiceSummaryTableHeader.AUDIT_DEPTH,
      order: "ascending",
    },
    [ServiceDetailsKey.FREIGHT]: {
      key: FreightTableHeader.CARRIER,
      order: "ascending",
    },
  },
};

type SetServiceSortCriteriaPayload = {
  service: ServiceDetailsKey;
  sortCriteria: ServiceSortCriteria;
};

export const ServiceSortSlice = createSlice({
  name: "serviceSort",
  initialState,
  reducers: {
    setServiceSortCriteria: (
      state,
      action: PayloadAction<SetServiceSortCriteriaPayload>,
    ) => {
      state.sortCriteria[action.payload.service] = action.payload.sortCriteria;
    },
  },
});

export const { setServiceSortCriteria } = ServiceSortSlice.actions;

export const selectServiceSortCriteria = (
  state: RootState,
  key: ServiceDetailsKey,
) => state.serviceSort.sortCriteria[key];

export default ServiceSortSlice.reducer;
