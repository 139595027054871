import { ServiceDetails, ServiceDetailsKey } from "@/types/service";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type ServicesState = {
  servicesDetails: Record<string, ServiceDetails>;
};

const initialState: ServicesState = {
  servicesDetails: {},
};

type SetServicesDetailsPayload = {
  servicesDetails: Partial<Record<ServiceDetailsKey, ServiceDetails>>;
};

export const servicesDetailsSlice = createSlice({
  name: "servicesDetails",
  initialState,
  reducers: {
    setServicesDetails: (
      state,
      action: PayloadAction<SetServicesDetailsPayload>,
    ) => {
      state.servicesDetails = action.payload.servicesDetails;
    },
  },
});

export const { setServicesDetails } = servicesDetailsSlice.actions;

export const selectServiceDetails = (state: RootState, key: string) =>
  state.servicesDetails.servicesDetails[key];

export default servicesDetailsSlice.reducer;
