import { CreditSortCriteria, CreditTrackerTableHeader } from "@/types/credit";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

type CreditSortState = {
  sortCriteria: CreditSortCriteria;
};

const initialState: CreditSortState = {
  sortCriteria: {
    key: CreditTrackerTableHeader.CREDIT_REQUEST,
    order: "ascending",
  },
};

type SetCreditSortCriteriaPayload = {
  sortCriteria: CreditSortCriteria;
};

export const CreditSortSlice = createSlice({
  name: "creditSort",
  initialState,
  reducers: {
    setCreditSortCriteria: (
      state,
      action: PayloadAction<SetCreditSortCriteriaPayload>,
    ) => {
      state.sortCriteria = action.payload.sortCriteria;
    },
  },
});

export const { setCreditSortCriteria } = CreditSortSlice.actions;

export const selectCreditSortCriteria = (state: RootState) =>
  state.creditSort.sortCriteria;

export default CreditSortSlice.reducer;
